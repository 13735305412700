import A from './actionTypes'

const localStorageKey = process.env.REACT_STORAGE_UI_KEY || 'questAppUI'

let localStorageState

try {
  localStorageState = localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : {}
} catch (e) {
  localStorageState = {}
}

const initialState = {
  ...{
    messages: [],
    showModal: false,
    modalContents: {},
  },
  // overwrite values from local state, if any
  ...localStorageState,
}

export default function root(state = initialState, action) {
  let newState = { ...state }
  let { messages } = state
  const { type, payload } = action
  console.debug('uiReducers:', type, payload)

  switch (type) {
    case A.SHOW_MESSAGE:
      if (Array.isArray(messages)) {
        // todo: validate payload type
        messages = [...messages, payload]
        newState = { ...state, messages }
      }
      break

    case A.DELETE_MESSAGE:
      console.debug('uiReducers: deleting message', payload, 'from', messages)
      if (messages[payload]) {
        messages.splice(payload, 1)
        console.debug('uiReducers: messages after deleting:', messages)
        newState = { ...state, messages: [...messages] }
      }
      break

    case A.CLEAR_MESSAGES:
      console.debug('uiReducers: clearing messages', messages)
      newState = { ...state, messages: [] }
      break

    case A.SHOW_MODAL:
      newState = { ...state, showModal: true, modalContents: action.payload }
      break
    case A.CLOSE_MODAL:
      newState = { ...state, showModal: false, modalContents: {} }
      break
    default:
      console.debug('uiReducers: no reducer selected')
    // do nothing
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState))

  return newState
}
