import React, {useState} from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import Image from '../image'
import {imgSrc} from '../../../../utils'
import "./vault2.scss"
import { getDefaultValues } from '../base-block'
/**
 * shows bgimage with intro_text and waits for the user to enter the code.
 * If code_length is true, string is reset after it's larger than the secret code
 * When the entered code matches "code" value, the sucess_image is shown
 * and a button with success_text will call the onNext() callback 
 * @param {*} props 
 */
const Vault = props => {

    // let {code: secretCode = "---------", 
    //     intro_text = "Введите код", 
    //     bgimage, code_length , 
    //     success_image, 
    //     success_text = "Дальше!", 
    //     show_success_screen = false,
    //     success_animation_length = 2000,
    //     success_animation = "animate__tada success",
    //     start_animation = "animate__zoomIn",
    //     description,
    //     promptProps = {},
    //     next,
    //     inputProps = {},
    //     textFieldProps = {},
    //     skipSuccessImage = false,
    //     onNext = n => console.log("next:" , n)
    // } = props
    let {
        code : secretCode, 
        intro_text, 
        bgimage, 
        code_length , 
        success_image, 
        success_text, 
        success_class,
        show_success_screen,
        success_animation_length,
        success_animation,
        prompt_start_animation,
        prompt_success_animation,
        start_animation,
        description,
        promptProps,
        next,
        inputProps,
        textFieldProps,
        skipSuccessImage,
        onNext = n => console.log("next:" , n)
    } = getDefaultValues( Vault.defaultConfig, props )

    console.debug("vault props:", props);

    // backward compatibility
    let prompt = props.prompt || props.text

    // make an array out of it and lowercase it
    secretCode = Array.isArray(secretCode) ? secretCode : [ secretCode ]
    secretCode = secretCode.map( c => String(c).toLowerCase() )

    // override 
    inputProps = { 
        style: { textAlign: 'center' }, 
        type: "number",
        ...inputProps
    }

    let [code, setCode] = useState("")


    const updateCode = newCode => {
        
        newCode = 
        // WARNING: THIS DOES NOT WORK ANYMORE as we're using array for secretCode
        // if code length is set...
        code_length  ? 
        // check if the new code is longer than that
        newCode.length > secretCode.length ? 
        // and if it's so, reset string
        "":
        // if length's ok - set the new code
        newCode :
        // ...and if no code length is set, just set the code
        newCode


        setCode(newCode)
        // If no success image is requested, go directly to the next page
        // if( skipSuccessImage &&  newCode.toLowerCase() === secretCode.toLowerCase() ){
            // console.debug("Codes:", secretCode, ", match: ", newCode )
        
            // if( skipSuccessImage &&  codeMatches(newCode) ){
        if( codeMatches(newCode) ){
            console.debug("Code matches!")
            return success()
        }
        
        console.debug("code didn't match:", newCode, "!=" ,secretCode);
        
    }

    const success = () => {
        console.debug("success is called, the timeout is set to", success_animation_length, "milliseconds")
        setTimeout( 
            // set delay to success animation length, then go to next page
            () => {
                console.debug("calling onNext with", next )
                onNext(next)
            }
            , success_animation_length 
        )
    }

    const codeMatches = codeToMatch =>  secretCode.includes( codeToMatch.toLowerCase() )

    const codeIsRight = codeMatches(code)

    console.debug("Vault defaultConfig:", Vault.defaultConfig);
    console.debug("Vault default data:", getDefaultValues(Vault.defaultConfig));

    const backgroundUrl = codeIsRight ? (success_image || bgimage ) : bgimage;

    return(
        <>
        {
        codeIsRight && show_success_screen &&
        <div className="vault" style={{backgroundImage: `url(${imgSrc(success_image)})` }}>
            <Button className="vault-success-button" onClick={() => onNext(next)}>{success_text}</Button>
        </div>
        }
        
        {/* { codeIsRight && !show_success_screen && <div className="vault" style={{backgroundImage: `url(${imgSrc(bgimage)})` }}>
            {
                prompt && <div className="prompt" {...promptProps}>{prompt}</div>
            }
            <TextField 
            inputProps
            label={intro_text}
            placeholder={code_length ? "*".repeat(secretCode.length) : "???" }
            value={code} 
            disabled
            {...textFieldProps}
            className={`vault-input animate__animated ${success_animation}`}
            />
        </div>} */}
        {(!codeIsRight || (codeIsRight && !show_success_screen)) && 
        <>
        <div className={`vault ${codeIsRight ? success_class : ''}`} style={{backgroundImage: `url(${imgSrc(backgroundUrl)})` }}>
            {
                prompt && <div {...promptProps} className={ `${promptProps.className || "prompt"} animate__animated ${
                    codeIsRight ? prompt_success_animation : prompt_start_animation}`} >{prompt}</div>
            }
            <TextField 
            // className="vault-input"
            inputProps
            label={intro_text}
            placeholder={code_length ? "*".repeat(secretCode.length) : "???" }
            value={code} 
            onChange={ ev =>  updateCode(ev.currentTarget.value) }
            {...textFieldProps}
            disabled={codeIsRight}
            className={`${textFieldProps.className || 'vault-input'} animate__animated ${ codeIsRight ? success_animation : start_animation}`}
            />
        </div>
        {description && <div className={`description ${start_animation}`}>{description}</div> }
        </>
        
        }
        {/* {`${code} : ${secretCode} : ${code === secretCode}`} */}
        </>
           
    )
}

Vault.defaultConfig = {
    type: "imageAuto",
    fields: {

        code: {
            input_type: "array",
            value: ["---"]
        } , 
        intro_text: {
            input_type: "text",
            value: "Введите код", 
        },
        bgimage : {
            input_type: "media",
            media_types: "image",
            value: ""
        }, 
        // code_length , 
        success_image : {
            input_type: "media",
            media_types: "image",
            value: ""
        },  
        success_text: {
            input_type: "text",
            value: "Дальше!", 
        },
        success_class: {
            input_type: "text",
            value: "success", 
        },
        show_success_screen: {
            type: "boolean",
            value: false
        },
        success_animation_length: {
            input_type: "int",
            min: 0,
            value: 2000
        },
        success_animation: {
            input_type: "string",
            value: "animate__tada success",
        },
        start_animation: {
            input_type: "string",
            value: "animate__zoomIn",
        },
        prompt_start_animation: {
            input_type: "string",
            value: "animate__zoomIn",
        },
        prompt_success_animation: {
            input_type: "string",
            value: "",
        },
        description: {
            input_type: "text",
            value: ""
        }, 
        promptProps: {
            input_type: "json",
            value: {}
        }, 
        next: {
            input_type: "page",
            value: null
        }, 
        inputProps:{
            input_type: "json",
            value: {}
        }, 
        textFieldProps: {
            input_type: "json",
            value: {}
        }, 
        skipSuccessImage: {
            input_type: "boolean",
            value: true
        }, 
        otherProps : {
            type: "object",
            input_type: "json",
            value: {}
        }
    }
}

export default Vault