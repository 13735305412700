const ActionTypes = {
    RESET_GAME: "RESET",
    SET_PLAYER: "SETPL",
    CLEAR_PLAYER: "CLEARPL",
    SET_MAP_POSITION: "SETMPPOS",
    KILL_PLAYER: "KILL",

    NAVIGATE: "NAV",
    NAVIGATE_BACK: "NAVBACK",
    PAGE_DONE: "ONCE",
    INIT_QUEST: "INIT",

    GAME_STATE_UPDATE: "GAME_STATE_UPDATE",
    GAME_STATE_VALUE_UPDATE: "GAME_STATE_VALUE_UPDATE",
    GAME_STATE_RESET: "GAME_STATE_RESET",
    SHOW_MESSAGE:"SHOW_MESSAGE",
    DELETE_MESSAGE:"DELETE_MESSAGE",
    CLEAR_MESSAGES:"CLEAR_MESSAGES",
}


export default ActionTypes