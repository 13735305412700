import React from 'react'
import ArrayItems from './array-items'
import BaseControl from './base-control'
import ObjectItems from './object-items';
import StringArray from './string-array';
import Text from './text';

const controlMap = {
    string: BaseControl,
    "array items": ArrayItems,
    "object items": ObjectItems,
    text: Text,
    "array": StringArray,
}
export default controlMap;


export const getControl = ( type ) => {
    return controlMap[type] || (() => <div className="menu-item">
        Unknown control type!
    </div> )
}
