import { Box, Paper, Typography } from '@material-ui/core'
import React from 'react'


class P404 extends React.Component{

    render(){
        const { header = "Error 404: Page not found", error = "Please, try reloading page" } = this.props;
        return( <Box padding={2} textAlign="center" flexGrow={1}>
            <Typography variant='h1'>{header}</Typography> 
            <Typography>{error}</Typography>
        </Box> )
    }
}


export default P404