import React from 'react';
import './App.scss';
import Main from './components/main'
import "animate.css"

function App() {
  return <Main/>
  
}

export default App;
