export const MEDIA = process.env.REACT_APP_MEDIA

export const imgSrc = (img) => `${MEDIA}${img}`

/**
 * Roughly compares a to b by stringifying both.
 * WARNING: a and b cannot contain 'undefined' values!
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
export const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b)

/**
 * Copy through JSON stingigy/parse
 * @param {*} a
 * @returns
 */
export const Copy = (a) => JSON.parse(JSON.stringify(a))
