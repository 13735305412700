import React from 'react'
import "./dying.scss"
import {imgSrc} from '../../../../utils'
class Dying extends React.Component{

    render(){
        const {player, isImpostor = false, onNext = console.log, next } = this.props
        return(
            <div className="dying" onClick={ () => onNext(next) } style={{backgroundImage: imgSrc("stars.png")}}>
                <div className={`caption${isImpostor && " impostor"}`}>
                    {player.name} was { !isImpostor && "not an"} an impostor{ isImpostor ? "!" : "..."}
                    <div className="player">
                        <img src={imgSrc(player.sprite)} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Dying