import { Button } from '@material-ui/core'
import React from 'react'
import './qr.scss'
// import qrScanner from 'qr-scanner'
// import {QrReader as QrScanner} from 'react-qr-reader'
import QrScanner from 'react-qr-scanner'
import {imgSrc} from  '../../../../utils'
const MEDIA = process.env.REACT_APP_MEDIA


const defaultScannerProps = {
    style: {
        width: "250px",
    },
    onError: console.error,
    delay: 300,
    facingMode: "rear"
}

/**
 * Shows a 'start scan' button with buttonText on given background image (bgimage) 
 * On clicking the button, scanning starts.
 * If scanned code coincedes with props.code, onNext( nextSuccess ) is called
 * If scanned code is wrong, onNext( nextFail ) is called
 */
class QrScan extends React.Component{

    state = {
        isScanning: false
    }
    
    // Scanner = null 

    componentDidMount(){
        
        // const scan = document.getElementById( this.props.id )
        // if( scan ){
        //     console.debug("Setting scanner to ", this.props.id )
        //     this.Scanner = new qrScanner( scan, this.handleScanned );
        // } else {
        //     console.debug("Scanner element wasn't found")
        // }
    }

    handleScanned  = result  => {
        
        
        
        // if( this.Scanner)
        //     this.Scanner.stop();

        if( result ){
            // this.setState({isScanning: false} )
            console.debug("Scan result:", result);            

            let {
                code, 
                onNext = value => console.log("Next is:", value) ,
            } = this.props


            code = String(code).toLowerCase()
            result = String(result.text).toLowerCase()

            let {nextSuccess, nextFail,  next, fail, id, notify, errorText = "Wrong code!" } = this.props;

            // fallback to nextSuccess for backward compatiility
            nextSuccess = nextSuccess || next
            // fallback to .fail or current item id
            nextFail = nextFail  || fail || id

            console.debug("Comparing:", `'${code}' to '${result}' \n result: ${ code === result }`)
            if( result === code ){
                console.debug("Success. Going to ", nextSuccess)
                onNext( nextSuccess )
            } else {
                console.debug("Fail. Going to ", nextFail)
                if( nextFail === id && typeof notify === "function" ) {
                    // if going to the same page - show notification (if available as function)
                    notify(errorText)
                } 
                onNext( nextFail )
            }
        }

    }

    render(){

        const {
            // id = "qrScan",
            bgimage, 
            // prompt,
            promptProps = {},
            // videoProps = {},
            buttonText,
            buttonProps = {},
            scannerProps = {},
            start_animation = "animate__zoomIn"
        } = this.props

        
        const {isScanning} = this.state

        // .prompt is backward compatibility
        const prompt = this.props.prompt || this.props.text 

        // if( !Array.isArray(buttons)) return <div>Error...</div>
        
        return(
            <div className="qr-scanner-block">
                <div className="background" style={ {backgroundImage: `url(${imgSrc(bgimage)})` }}> 
                    
                    {prompt && <div {...promptProps} className={ `${promptProps.className || "prompt"} animate__animated ${start_animation}`}>{prompt}</div> }
                    
                    { isScanning ?
                    
                        <QrScanner                             
                            onScan={ this.handleScanned }
                            // use default props and shallow-overwrite them with passed props
                            {...{...defaultScannerProps, scannerProps}}
                            // delay={300}
                            // onError={console.error}
                            // style={{ width: '250px' }}
                            className={`animate__animated ${start_animation}`}
                        />:

                        <Button className="start-button" {...buttonProps} onClick={
                            () => {
                                this.setState({isScanning: true})
                            }
                        }>{buttonText}</Button>
                    }
                    
                    
                </div>
            
            </div>
        )
    }
}

export default QrScan