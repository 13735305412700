import { Button, Grid, Typography } from '@material-ui/core'
import React from 'react'
import {connect} from 'react-redux'
import A from  '../../../../redux/actionTypes'
import {imgSrc} from '../../../../utils'
import Dying from '../dying'
import './vote.scss'

class Vote extends React.Component{


    state = {
        killed: null
    }

    kill( player ){
        const {me} = this.props
        // cant kill myself
        if( me.id !== player.id ){
            this.setState({killed: player})
            this.props.killPlayer(player)
        }
    }

    render(){

        const {killed} = this.state
        const {player, players, onNext, next, isImpostor = false } = this.props

        return(
        <div className="voting">
            {killed ? <Dying onNext={onNext} next={next} player={killed} isImpostor={isImpostor} /> :
            <div className="frame">
            <Typography variant="h1">Who is the impostor?</Typography>
            <Grid container spacing={4}>
                { players.map( p => (
                <Grid item xs={12} sm={6}>
                    <Button onClick={() => this.kill(p)}>
                        <img className="vote-avatar" src={imgSrc(p.avatar)}  />
                        <span>{p.name}</span>
                    </Button>
                </Grid>
                ))}
            </Grid>
            </div>
            }
        </div>
        )
    }
}

export default connect(
    state => ({
        players: state.players,
        me: state.player
    }),
    dispatch => ({
        killPlayer: payload => dispatch({type: A.KILL_PLAYER, payload })
    })
)(Vote)