import React from 'react'
import {connect} from 'react-redux'
import A from '../../../../redux/actionTypes'
import {imgSrc} from '../../../../utils'
import "./among-us.scss"
import blocks from '../../blocks'


const mapContainerSize = {w: 420, h : 280 }
// const MEDIA = process.env.REACT_APP_MEDIA
class AMongUsGame extends React.Component{


    componentDidMount(){
        this.updatePosition()
    }


    componentDidUpdate( oldProps){
        if( oldProps.id !== this.props.id ){
            this.updatePosition()
        }
        
    }

    updatePosition(){
        const {position, setPosition } = this.props
        if( position !== null ){
            setPosition(position)
            
            if( position )
                this.scrollToPosition(position)
        }
    }

    scrollToPosition = (pos)=> {
        const el = document.getElementById("map-container")
        const xoff = Math.max( pos.x - mapContainerSize.w/2, 0)
        const yoff = Math.max( pos.y - mapContainerSize.h/2, 0)
        console.debug("SCrolling to ", xoff, yoff)
        
        el.scrollTo( { 
            top: yoff,
            left: xoff ,
            behavior: "smooth"
         } )
    }


    render(){
        const {id, map_position, player, top_left, onNext = console.log, players } = this.props
        let Renderer
        if (top_left ){
            Renderer =  blocks[top_left.type ]
        }

        return(
            <div className="game-main">
                <div className="top-left">
                    {Renderer && <Renderer {...top_left} onNext={onNext} key={id}/>}
                </div>
                <div className="avatar" ><img src={imgSrc(player.avatar)} />{player.name}</div>
                <div className="players-list">
                    {players.map( p => (
                        <div className="player-avatar">
                            <img src={imgSrc(p.avatar)} />
                            <span>{p.name}</span> 
                        </div>
                    ))}
                </div>
                <div id="map-container">
                    <img className="map" src={imgSrc('map.jpg')}/>
                    {map_position ? 
                    <img className="position" 
                    src={imgSrc("position.png")} 
                    style={{left: `${map_position.x}px`, top: `${map_position.y}px`}} 
                    /> :
                    ""}
                </div>
            </div>
        )
    }
}

export default connect(
    state => {
        const {player, map_position, players } = state
        return {player, map_position, players }
    },

    dispatch => ({
        setPosition: pos => dispatch({type: A.SET_MAP_POSITION, payload: pos} )
    })
)(AMongUsGame)