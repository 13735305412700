import vault from './vault'
import vault2 from './vault2'
import image_with_buttons from './image-with-buttons'
import image from './image'
import imageAuto from './imageAuto'
import touchImage from './touch-image'
import overlayImage from './overlay-image'
import select_player from './select-player'
import among_us from './among-us-game'
import vote from './vote'
import video from './video'
import dying from './dying'
import random_dead from './random-dead'
import asteroids from './asteroids'
import qrScan from './qr-scan'

export default {
    vault,
    vault2,
    image_with_buttons,
    image,
    imageAuto,
    touchImage,
    overlayImage,
    select_player,
    among_us,
    video,
    vote,
    dying,
    random_dead,
    asteroids,
    qrScan
}