import axios from 'axios'
import store from '../redux/store'
import A from '../redux/actionTypes'

const { REACT_APP_API, REACT_APP_API_USE_ORIGIN } = process.env
const baseURL = REACT_APP_API_USE_ORIGIN
  ? window.location.origin + REACT_APP_API
  : REACT_APP_API
/**
 * A base client-side service class with maneagable cache and axios as .api property.
 *
 *
 * To create a descendant:
 * class NewClass extends baseClientService{
 *  ...
 * }
 * and then:
 * baseClientService.extend(NewClass) // this effectively copies the properties and methods
 *
 * When using .GET and .POST methods, the authorization header is added automatically if there is a token.
 * .GET and .POST are wrappers for axios's .get and .post and have the same parameters (see axios docs)
 *
 *
 *
 */
class baseClientService {
  static cache(index, value, subscriptionCallback) {
    this._CACHE[index] = value

    // attempt to call the callback, if exists
    if (
      Array.isArray(this._SUBSCRIPTIONS[index]) &&
      this._SUBSCRIPTIONS[index].length > 0
    ) {
      this._SUBSCRIPTIONS[index].forEach((cb) => {
        cb(index)
      })

      return
    }

    if (subscriptionCallback) {
      this.subscribe(index, subscriptionCallback)
    }
  }

  static subscribe(index, subscriptionCallback) {
    this._SUBSCRIPTIONS[index] = [
      ...(this._SUBSCRIPTIONS[index] || []),
      subscriptionCallback,
    ]
  }

  static getCached(index, defaultValue) {
    return this.cacheExists(index)
      ? this.Copy(this._CACHE[index])
      : defaultValue
  }

  /**
   * Returns cached result if found. If not, calls the callback with given index.
   * @param {*} index
   * @param {*} callback
   * @returns
   */
  static async getCachedOr(index, callback) {
    if (this.cacheExists(index)) {
      return this.Copy(this._CACHE[index])
    }

    return callback(index)
  }

  static cacheExists(index) {
    return this._CACHE.hasOwnProperty(index)
  }

  static clearCache(index) {
    if (index) {
      if (this.cacheExists(index)) {
        delete this._CACHE[index]
      }
    } else {
      this._CACHE = {}
    }
  }
  static Copy(x) {
    return JSON.parse(JSON.stringify(x))
  }

  static printCache() {
    console.debug('\n\n\nCache:\n', this._CACHE)
  }

  static Error(message) {
    this.error = message
    return false
  }

  static extend(ancestor) {
    Object.assign(ancestor, this)
  }

  static prepareConfig(config = {}) {
    // just in case...
    config = typeof config === 'object' ? config : {}
    // get authorization header from redux state
    const { authorization } = store.getState().root
    // console.debug('Redux state', store.getState());

    // get headers from passed config, if any
    let { headers = {} } = config
    // add authorization header
    headers = { ...headers, authorization }

    return { ...config, headers }
  }

  /**
   * Simple wrapper for axios get() method that adds authorization header from redux state.
   * Will throw error on request error.
   * @param {*} url
   * @param {*} config
   */
  static async GET(url, config = {}) {
    try {
      const { data } = await baseClientService.api.get(
        url,
        this.prepareConfig(config),
      )
      return data
    } catch (e) {
      return this.catchRequestError(e)
      // console.log( "Error: ", e.requestData )
      // if (e.response) {
      //   const user = store.getState().user;

      //   //console.log(e.response);
      //   if (e.response.status === 401 && user ) {
      //     // oops, session terminated
      //     // logout
      //     this.dispatch(A.LOGOUT);
      //     // show message
      //     this.dispatch(A.SHOW_MESSAGE, {
      //       message: 'Your session expired, please log in again',
      //       type: 'warning',
      //     });
      //   }
      // }
      // throw new Error(e.message);
    }
  }

  /**
   * Simple wrapper for axios post() method that adds authorization header from redux state. Will throw error on request error.
   * @param {*} url
   * @param {*} requestData
   * @param {*} config
   */
  static async POST(url, requestData = {}, config = {}) {
    try {
      const result = await baseClientService.api.post(
        url,
        requestData,
        this.prepareConfig(config),
      )

      const { data } = result
      return data
    } catch (e) {
      return this.catchRequestError(e)
    }
  }

  static catchRequestError(e) {
    // console.debug('POST error:', [e]);
    if (e.response) {
      // if (e.response.status === 401) {
      const user = store.getState().root.user

      //  if 401 and user is logged in - log them out
      if (e.response.status === 401 && user) {
        console.debug('status 401', e.response)
        // oops, session terminated
        // logout
        this.dispatch(A.LOGOUT)
        // show message
        this.dispatch(A.SHOW_MESSAGE, {
          message: 'Your session expired, please log in again',
          type: 'warning',
        })
        return
      }
      // console.debug("POST error", e.response, "\nstore user:", user)
      throw new Error(e.response.data || e.message || 'Unknown error happened')
    }
    throw new Error(e.message)
  }

  static async dispatch(type, payload) {
    return store.dispatch({ type, payload })
  }

  /* static action(actionName, actionArguments) {
    try {
      return Actions[actionName].apply(null, actionArguments);
    } catch (e) {
      console.error(`Error in action ${actionName}: `, e.message);
      return false;
    }
  } */
}

baseClientService._CACHE = {}
baseClientService._SUBSCRIPTIONS = {}

baseClientService.api = axios.create({
  baseURL,
})

// action types
baseClientService.actionTypes = A

export default baseClientService
