import { List, TextField, Typography } from '@material-ui/core'
import React from 'react'
import BaseControl from './base-control';
import { getControl } from './control-map';
import {notify} from '../../../redux/notificationActions';


class ArrayItems extends BaseControl{
   

    updateItem = ( itemId, newValue ) => {
        
        // id is of ArrayItems element, we need to use onChange on it's data (value)
        const {id, idField = "id",  value = [], onChange} = this.props; 

        // find item index in value array 
        console.debug("looking for", itemId, "in", value)
        const index = value.findIndex( i => i[idField] === itemId )
        
        console.debug("index found:", index)
        if( itemId && index >= 0 ) {
            value[index] = newValue
            
            // update value        
            return onChange( itemId, value )

            //throw new Error(`Error updating array item - item with id=[${itemId}] not found` )
            // console.error( `Error updating array item - item with id=[${itemId}] not found` );
            // return notify(`Ошибка при обновлении значения [${itemId}] в массиве: запись не найдена`)
        } 
        
        console.debug("item with id=", itemId, "not found, creating a new one" );
        // add a new record to the array
        value = [...value, {...newValue, [idField]: itemId } ] ;
        // update value        
        onChange( id, value )
    }


    render(){
        const { id, label, descr, items = [], value = [] } = this.props;
        console.debug("array items", id, "\nitems:\n", items, "\nvalues:\n", value)

        return <div className="array-items">
            <Typography variant='h3'>{label || "???"}</Typography>
            { descr && <Typography variant='body'>{descr}</Typography> }
            <List>
                {Array.isArray(items) && items.map( (item) => {

                    const {id, input_type, value: defaultValue , ...other} = item;
                    const Control = getControl(input_type)
                    const index = value.findIndex( i => i.id === id )
                    return <Control key={`array-item-${id}`} 
                        {...other} 
                        id={id}
                        // get corresponding item from value, or fall back to 'value' field from the item in template
                        value={value[index] || defaultValue } 
                        onChange={this.updateItem} 
                        notify={notify}
                    />
                } )}
            </List>
        </div>
    }
}

BaseControl.extend(ArrayItems);

export default ArrayItems