import { AppBar, Button, Container, TextField, Typography, Menu, MenuItem } from '@material-ui/core'
import React, {useState} from 'react'
import { Link, withRouter } from "react-router-dom";
// import Vault from './blocks/vault'
import Image from './blocks/image'
// import page1 from './images/page1-100.jpg'
// import page2 from './images/page2-100.jpg'
// import page3 from './images/page3-100.jpg'
// import page4 from './images/page4-100.jpg'
// import page5 from './images/page5-100.jpg'

// import quests from '../../data/quests'
import blocks from './blocks'
import {connect} from 'react-redux'

import {Menu as Hamburger} from '@material-ui/icons'

import A from '../../redux/actionTypes'

import "./style.scss"
import QuestService from '../../services/quest-service';
import P404 from '../template/404';
// import ActionTypes from '../../redux/actionTypes';
import { notify } from '../../redux/notificationActions';
import { imgSrc } from '../../utils';

// const MEDIA = process.env.REACT_APP_MEDIA

// const quest = {
//     1: {
//         title: "Лёвоквест",
//         pages : [
//             {
//                 id: "asec", 
//                 message: "Добро пожаловать в цифровой Лёвоквест! Следуйте загадочным инструкциям и думайте головой!" ,
//                 next: "asec2"
//             },
//             {
//                 id: "asec2", 
//                 title: "Лёвоквест", 
//                 image: page1, 
//             },
                
//             {id: "crvv", title: "Лёвоквест", image: page2 },
//             {id: "oirt", title: "Лёвоквест", image: page3 },
//             {id: "njuh", title: "Лёвоквест", image: page4 },
//             {id: "wafb", title: "Лёвоквест", image: page5, code: "38563" },
//         ]
//     },
//     2: {
//         title: "Юлоквест",
//         pages : [
//             {id: "asec", title: "Лёвоквест", image: page1, message: "Добро пожаловать в цифровой Юлоквест! Следуйте загадочным инструкциям и думайте головой!" },
//             {id: "crvv", title: "Лёвоквест", image: page2 },
//             {id: "oirt", title: "Лёвоквест", image: page3 },
//             {id: "njuh", title: "Лёвоквест", image: page4 },
//             {id: "wafb", title: "Лёвоквест", image: page5, code: "38563" },
//         ]
//     }
// }

// const pages = [
//     {id: "asec", title: "Лёвоквест", image: page1, message: "Добро пожаловать в цифровой Лёвоквест! Следуйте загадочным инструкциям и думайте головой!" },
//     {id: "crvv", title: "Лёвоквест", image: page2 },
//     {id: "oirt", title: "Лёвоквест", image: page3 },
//     {id: "njuh", title: "Лёвоквест", image: page4 },
//     {id: "wafb", title: "Лёвоквест", image: page5, code: "38563" },
// ]



// const Message = props => {

//     let [open, setOpen] = useState(true)

//     const {text, image} = props

//     return( 
//         open ? 
//         <Container className="message-container">
//             <div className="message">
//                 <Typography>{text}</Typography>
//                 <Button variant="contained" color="primary" onClick={() => setOpen(false)}>Поехали!</Button>
//             </div>
//         </Container> 
//         : <Image image={image} />
//     )
// }

// const Oops = () => <Typography>Error loading page, please try reloading</Typography>
const Oops = () => <P404 error="Error loading page, please try reloading" header="Error" />

class Page extends React.Component{


    state = {
        quest: null, 
        page: null,
        code: null,
        menuAnchor: null,
        error: null
    }

    async componentDidMount(){
        const { id, code } = this.props.match.params 
        const {error } = this.state;

        if( !error && !this.props.quest ||  
            !this.props.page || 
            (this.props.quest && this.props.quest.id !== id) ){
            try{
                // then fetch the quest data
                await this.initPage(id, code )
            }catch(e){
                console.debug(e);
                this.setState({error: e.message});
            }
        }
    }

    

    async initPage( questid, pageid ){
            console.debug("initPage called with", questid, pageid);
            
            let {pages_done, quest, page} = this.props
            // let quest, page
            
            console.debug("Quest ID:", questid, "PageID:", pageid, "page:", page)

            if( !quest || (quest && quest.id !== questid) ){
                console.debug("no quest or quest id changed")

                // we have a new quest, let's fist reset the data in redux
                // this.props.action_dispatch( {type: A.RESET_GAME} )

                // quest = quests.find( q => q.id === questid )
                // quest = await QuestService.get(questid);
                quest = await QuestService.get2(questid);
                
                console.debug("New quest: ", quest) 
                console.debug("New quest. Quest pages: ", quest.pages) 

                // add video prefetch links to head
                this.addPreloadVideoLinks( quest );
                
                if( !page ){
                    // if no page id is passed - go to startPage index
                    page = quest.pages.find( i => i.id === (pageid || quest.startPage) )
                }
                console.debug("Page is:", page);

                return this.props.action_dispatch( {type: "INIT", payload: {quest, page} } )

                
            } 

            // quest but no page
            if( quest && !page ){
                
                console.debug("quest and no page");
                // if no page id is passed - go to startPage index
                page = pageid || quest.startPage
                console.debug("Newly selected page is:", page);
                this.props.action_dispatch({type: A.NAVIGATE, payload: pageid })

            }

            // this.setState ({quest, page})

            if( page && page.action ){ 
                console.log( "Page action is: ", page.action )
                // store.dispatch( () => (page.action) )

                // only if the page's action isn't marked as already done - perform it
                if( !pages_done.includes(page.action) ){
                    this.props.action_dispatch(page.action)
                    // if should be done only once, add it to done stack
                    if( page.once ){
                        this.props.page_done( page.action )
                    }
                } else {
                    // TODO: fallback
                }

            }

            console.debug("End initPage");
        
    }

    async componentDidUpdate( prevProps ){
        
        // const { quest, page } = this.props.match.params 
        const { id, code  } = this.props.match.params 
        const {error} = this.state;

        // if no quest in props (meaning no quest in redux)
        // or page id/quest id has changed - fetch page data
        if ( !error && !this.props.quest || ! this.props.page ||
            // (this.props.page && prevProps.page && this.props.page.id !== prevProps.page.id  ) ||
            (this.props.quest && this.props.quest.id !== id  )            
            ){
            try{
                console.debug("component did update and something has changed:", this.props, prevProps)
                // if( this.props.quest.id !== id || this.props.page.id !== code ){
                return await this.initPage(id, code )

            }catch(e){
                console.debug("Error happened", e.message)
                console.debug(e);
                this.setState({error: e.message || "Unknown error"});
            }
            
        }

        
    }

    /**
     * Adds/replaces preload links to html
     */
    addPreloadVideoLinks = ( quest) => {
        // <link rel="prefetch" href="short.mp4" as="video" type="video/mp4">
        const linkProps = {
            rel: "prefetch",
            as: "video",
            type: "video/mp4",            
            "data-prefetch-link": true 
        }

        if( !Array.isArray(quest.pages) ) return console.error("addPreloadVideoLinks: no pages found in quest");


        // remove all previously added prefetch links
        console.debug("removing links", document.querySelectorAll("[data-prefetch-link]"));

        document.querySelectorAll("[data-prefetch-link]").forEach( l => l.remove() );

        // get only pages with video
        quest.pages.filter( p => p.video ).forEach( page => {
            const link = document.createElement("link");
            // add default link props
            Object.keys(linkProps).forEach( key => link.setAttribute(key, linkProps[key]) );
            // add href 
            link.href = imgSrc(page.video);

            console.debug("adding a new link element", JSON.stringify(link));
            document.head.appendChild( link );
            console.debug("link element added");            
        });



    }


    

    render(){
        // const {id, num } = this.props.match.params
        // const {quest: questid, page: pageid } = this.state 

        // const quest = quests.find( q => q.id === questid )

        console.debug("page props:", this.props);
        
        const {error} = this.state;

        if( error ) return <P404 {...{header: "Error", error}} />
        
        let {quest, page, gameState} = this.props 

        if( !quest || !quest.pages ) return <Oops />

        // const page = quest.pages.find( i => i.id === pageid )
        
        if( !page) return <Oops />
        
        const {type, title, before, after, nextConditions, isFinal, finalMessage = "Congratulations!"  } = page        

        const Renderer = blocks[type] || Oops

        const RendererBefore = before && before.type ? blocks[before.type] : null
        const RendererAfter = after && after.type ? blocks[after.type] : null

        /**
         * TODO: optimize - move this and updateNextPageFromConditions to component scope
         */
        const next = (nextPageIndex) => {
            console.log( "next() is called with:", nextPageIndex )
            console.debug("next() : current page is", page)

            // if it's the final page, show final message 
            if( isFinal ){
                console.debug("next() : the page is final, showing final message")
                return notify(finalMessage, "success");
            }

            // navigate to another page of the same quest, if any
            if( !nextPageIndex || !quest || !quest?.id ){
                console.debug("navigation error. nextPageId/quest/quest.id:", nextPageIndex, quest, quest?.id );
                // show message
                return notify("Ошибка навигации");                
            }

            // check if there's a nextConditions in page, and if found - update the next page index
            // TODO: probably use joi to validate the object
            if( (nextConditions && typeof nextConditions === "object" && nextConditions.type && nextConditions.values )){
                nextPageIndex = updateNextPageFromConditions(nextPageIndex);    
            }      
                
            console.debug("next() : navigating to (updated) next page:", nextPageIndex);
            this.props.navigate(nextPageIndex)            
        }


        /**
         * use page.nextConditions value to 
         * @param {string} nextPageIndex 
         * @returns 
         */
        const updateNextPageFromConditions = ( nextPageIndex ) => {
            console.debug("next() : conditions found ", nextConditions);

            // there's a condition set for whether to go next based on conditions.
            switch( nextConditions.type ){
                
                // choose next page based on a specific gameState value:
                // get value from stateValue, compare it to keys of nextConditions.options
                // if key exists, use value of the option as index of the next page.
                // Format: { type: "state-value", key: "team_id", values: {1 : "dyno", 2: "knight": 2: "egypt"}}
                case "state-value":{
                    console.debug("next() : condition is state-value type")
                    if( !nextConditions.key || typeof nextConditions.values !== "object" ) break;


                    if( !gameState || typeof gameState !== "object"){
                        console.error("next(): gameState is not valid. Expected object, found:", gameState);
                        break;
                    }

                    // get value from gameState
                    const stateValue = gameState[nextConditions.key];
                    console.debug("next(): looking for key", stateValue, "in", )
                    // break if not found (TODO: probably throw an error here or show a message)
                    if( stateValue === undefined ) {
                        console.error("nextConditions.key is not found. key:", nextConditions.key, ", gameState:", gameState)
                        break;
                    }

                    const keys = Object.keys(nextConditions.values);

                    // if key is found, use its value as the next page index
                    nextPageIndex = keys.includes(stateValue) ? nextConditions.values[stateValue] : nextPageIndex;

                }
                    break;
                default:
                    console.debug("next() : no existing processor found for type:", nextConditions.type );
                    break;
            }     

            return nextPageIndex;
        }

        const {bg, cssclass = "" } = quest

        const {menuAnchor} = this.state

        const handleClose = what => {
            
            this.setState({menuAnchor: null})
            switch( what){
                case "fw":
                    console.debug("Forward")
                    next( page.next || page.nextSuccess )
                    break;
                case "bk":
                    console.debug("BAck")
                    this.props.action_dispatch({ type: A.NAVIGATE_BACK })
                    break;
                case "restart":
                    if( window.confirm("Вы действительно хотите перезапустить квест?") ){
                        this.props.action_dispatch({ type: A.RESET_GAME })
                    }
                    break;
            }
        } 
        const handleOpen = ev => this.setState({menuAnchor: ev.currentTarget})

        return(
            page ?
            <div className={`quest-page ${cssclass}`}>
            {/* <div className={`quest-page ${cssclass}`} style={{backgroundColor: bg || "white" }}> */}
                <AppBar className="top-bar">
                    <div></div>
                    <div>{title || quest.title }</div>
                    <Button onClick={handleOpen}><Hamburger/></Button>
                    <Menu
                    id="cntrol-menu"
                    anchorEl={menuAnchor}
                    keepMounted
                    open={Boolean(menuAnchor)}
                    onClose={handleClose}
                    >
                        <MenuItem onClick={() => handleClose("fw")}>Вперёд</MenuItem>
                        <MenuItem onClick={() => handleClose("bk")}>Назад</MenuItem>
                        <li aria-disabled={true}><hr/></li>
                        <MenuItem component={Link} to={`/quest`}>К списку</MenuItem>
                        <li aria-disabled={true}><hr/></li>
                        <MenuItem onClick={() => handleClose("restart")}>Перезапуск</MenuItem>
                    </Menu>
                    {/* <Button onClick={() => this.props.action_dispatch({ type: A.RESET_GAME })}>Restart</Button> */}
                </AppBar>
                { RendererBefore && <RendererBefore {...before} /> }
                <Renderer {...page} onNext={ next } />
                { RendererAfter && <RendererAfter {...after} /> }
                
                
            </div>
            : <Oops />
        )
    }
}

export default connect( 
    state => ({ 
        pages_done: state.quest.pages_done, 
        page: state.quest.page, 
        quest: state.quest.quest,
        gameState: state.quest.gameState
    }),
    dispatch => ({
        action_dispatch: a => dispatch(a),
        navigate: payload => dispatch( {type: A.NAVIGATE, payload } ),
        page_done: payload => dispatch( {type: A.PAGE_DONE, payload } ),
    })
)( withRouter( Page) )