import React, {useState} from 'react'
import { TextField, Typography } from '@material-ui/core'
import Image from './image'

const Vault = props => {

    const {code: secretCode, image} = props

    let [code, setCode] = useState("")

    return(
        <>
        {
        code === secretCode ? <Image image={image} /> 
        :
        <div className="vault">
            <TextField 
            label="Введите секретный код:"
            placeholder="*****"
            value={code} 
            onChange={ ev => setCode(ev.currentTarget.value)}
            />
        </div>
        }
        {/* {`${code} : ${secretCode} : ${code === secretCode}`} */}
        </>
           
    )
}

export default Vault