import { CircularProgress, TextField } from '@material-ui/core'
import React from 'react'
import { Copy, equals } from '../../../utils'

class StringArray extends React.Component{

    state = {
        value: null,
        original: null
    }

    componentDidMount(){
        const {value = []} = this.props;
        console.debug("array value in componentDIdMount:", value)
        this.setState({ value: Copy(value), original: Copy(value ) })
    }

    componentDidUpdate(prevProps){
        const {value = []} = this.props;
        const {value: prevValue = []  } = prevProps;
        if( !equals(value, prevValue)) 
            this.setState({ value: Copy(value), original: Copy(value) })
    }

    onChange = ({target}) => {
        let {value} = target;
        const {id, onChange} = this.props;

        value = value.split("\n")
        this.setState({value}, () => onChange( id, value ));
    }

    render(){
        console.debug("control props id:", this.props)
        const {id, type, onChange, value: propsValue , ...other} = this.props;
        const {value = [], original = [] } = this.state;

        console.debug("atring array value:", value)
        if( !value ) return <CircularProgress /> 
        return (
        <div className="array">
            <TextField fullWidth multiline minRows={3} maxRows={6} 
            value = { value.join("\n") }
            {...other } 
            onChange={this.onChange} />
        </div>
        )
    }
}

export default StringArray