import A from './actionTypes'
const localStorageKey = 'questAppStorage'

let localStorageState

try {
  localStorageState = localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : {}
} catch (e) {
  localStorageState = {}
}

const baseState = {
  player: null,
  map_position: null,
  players: null,
  killed: [],
  quest: null,
  page: null,
  pages_done: [],
  page_history: [],
  gameState: {}
}

const initialState = {
  ...baseState,
  // overwrite values from local state, if any
  ...localStorageState,
}

export default function root(state = initialState, action) {
  let newState = { ...state },
    quest,
    page,
    page_history,
    oldPage

  console.debug('Dispatching action:', action)
  const { type, payload } = action

  //   console.debug('Type of action:', type)

  switch (type) {
    // case A.RESET_GAME:
    case A.RESET_GAME:
      //   console.debug('reset game reducer called')
      // pass players list to reset
      newState = {
        ...initialState,
        players: payload,
        killed: [],
        pages_done: [],
        quest: null,
        page: null,
        page_history: [],
        gameState: {}
      }
      console.debug('RESET state:', newState)
      break

    case A.INIT_QUEST: {
      const { quest, page } = payload;

      // get game state, if any
      const {state : gameState = {}} = quest;

      newState = {
        ...baseState,
        players: payload.players,
        killed: [],
        pages_done: [],
        quest,
        page,
        page_history: [],
        gameState
      }
    }

      break
    case A.NAVIGATE:
      ;({ quest, page_history, page: oldPage } = state)
      console.debug('NAvigating to ', action.payload)
      if (quest && quest.pages) {
        page = quest.pages.find((p) => p.id === action.payload)
        console.debug('Page found:', page)
        if (page)
          newState = {
            ...state,
            page,
            page_history: [...page_history, oldPage.id],
          }
      }
      break

    case A.NAVIGATE_BACK:
      ;({ quest, page_history } = state)
      console.debug('Navigating back.')
      if (quest && quest.pages && page_history.length > 0) {
        const cut_history = [...page_history]
        const lastId = cut_history.pop()
        console.debug('Navigating back to ', lastId)
        page = quest.pages.find((p) => p.id === lastId)
        console.debug('Page found:', page)
        if (page) newState = { ...state, page, page_history: cut_history }
      }
      break

    case A.PAGE_DONE:
      const { pages_done } = state
      newState = { ...state, pages_done: [pages_done, action.payload] } // todo: remove duplicates
      break
    case A.SET_PLAYER:
      newState = { ...state, player: action.payload }
      break
    case A.CLEAR_PLAYER:
      newState = { ...state, player: null }
      break
    case A.SET_MAP_POSITION:
      newState = { ...state, map_position: action.payload }
      break
    case A.KILL_PLAYER:
      // remove player from players array and add to killed
      let { players, killed } = state
      if (Array.isArray(players) && players.length > 0) {
        const ind = players.findIndex((p) => p.id === action.payload.id)
        if (ind > -1) players.splice(ind, 1)
        newState = { ...state, players, killed: [...killed, action.payload] }
      }
      break
   


      case A.GAME_STATE_RESET: {

        const {state: gameState = {} } = (state.quest || {});
        newState = {
          ...newState,
          gameState
        }
      }
      break;

      case A.GAME_STATE_UPDATE: {
        // override gameState values from payload (must be an object)
        const {gameState = {} } = state;

        // tODO: probably handle the errors here 
        if( typeof gameState !== "object") gameState = {}
        if( typeof payload !== "object") payload = {}

        newState = {
          ...newState,
          gameState: {...gameState, ...payload}
        }
      }

    default:
      // newState = state;
      console.debug('no reducer found for', type)
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState))

  return newState
}
