import React from 'react'
import {Box, List, ListItem, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'

class Home extends React.Component{

    render(){
        return(
            <Box padding={1}>

            <Typography variant="h1" color="primary">Quest Family</Typography>
            <List>
                <ListItem><Link to="/quest">Список квестов</Link></ListItem>
            </List>
            </Box>
        )
    }
}

export default Home