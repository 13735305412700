import { Button } from '@material-ui/core'
import React from 'react'
import {connect} from 'react-redux'
import A from  '../../../../redux/actionTypes'
import {imgSrc} from '../../../../utils'
import './asteroids.scss'

class RandomDead extends React.Component{

    state = {
        needed: 20
    }

    componentDidMount(){
        // start creating asteroids
        this.asteroids()


    }

    asteroids(){
        let delay = 1000
        const range = document.getElementById("shooting")
        
        const random = val =>  Math.floor( Math.random() * val );

        const shoot = () => {
            const asteroid = document.createElement('div');
            asteroid.classList.add("asteroid")
            range.append(asteroid)

            asteroid.style.top = random( 90 ) + "%";
            asteroid.style.left = random( 90 )  + "%";
            // asteroid.style.top = random( range.style.height ) + "px";
            // asteroid.style.left = random( range.style.width )  + "px";

            asteroid.onclick = ev => {
                console.log( "asteroid clicked")
                let  {needed} = this.state
                if( needed > 0){
                    needed -= 1
                    this.setState({ needed })
                    delay -= 20
                }
                ev.currentTarget.remove()
            }
            setTimeout( () => {
                asteroid.remove()
                if( this.state.needed > 0 ){
                    shoot()
                }
            } , delay  )

        }


        shoot();



    }

    render(){
        const {needed} = this.state
        const {onNext = console.log, next} = this.props
        return(
            
            <div className="asteroids">
                { needed > 0 ? 
                    <div className="shooting-range" id="shooting">
                        <div className="left">Left: {needed}</div>
                    </div> :
                    <div className="victory">
                        <div>You did it!</div>
                        <Button className="success-button" onClick={() => onNext(next)}>Дальше</Button>
                    </div>
                }       
                
            </div>
        )
    }
}

export default connect(
    state => ({
        player: state.player,
        players: state.players
    }),
    dispatch => ({
        // killPlayer: payload => dispatch({type: A.KILL_PLAYER, payload })
    })
)(RandomDead)