import React from 'react'
import {imgSrc} from '../../../../utils'
import "./overlay-image.scss"

const Image = props => {

    const {image, alt, className, ...other } = props

    return image ? 
    
        <img alt={alt || image} className={ className || "overlay-image" } src={imgSrc(image)}  {...other} /> 
    

    : "No image"

}

export default Image