import Home from '../components/home'
import QuestList from '../components/home/quest-list'
import Page from '../components/page'
import QuestEditor from '../components/quest-editor'
import P404 from '../components/template/404'
import quest_list from '../data/quest-data.json'

const components = {
  Home,
  Page,
  P404,
}

const quest_ids = quest_list.map((q) => q.id)
console.debug('Quest ids:', quest_ids)

export const routes = [
  { exact: true, path: '/', component: components['Home'] },
  { exact: true, path: '/quest', component: QuestList },
  { exact: true, path: '/quest/:id(new)', component: QuestEditor },
  { exact: true, path: '/quest/edit/:id/', component: QuestEditor },
  {
    exact: true,
    path: '/quest/edit/:id/:tab(pages|template)/:page?',
    component: QuestEditor,
  },
  // { exact: true, path: "/quest/:id/page/:num" , component: components["Page"] },
  {
    exact: true,
    path: `/quest/:id(${quest_ids.join('|')})/:code?`,
    component: components['Page'],
  },

  { exact: false, path: '*', component: components['P404'] },
]

export default routes
