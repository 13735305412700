import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import quest from './questReducers'
import ui from './uiReducers'
import notifications from '../components/template/notifications/reducers';

const root = combineReducers({
  quest,
  ui,
  notifications
})

const store = createStore(root, compose(applyMiddleware(thunk)))
export default store
