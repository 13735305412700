import { TextField } from '@material-ui/core'
import React from 'react'
import P404 from '../template/404';
import AdminTable from '../template/admin-table';


class QuestPages extends React.Component{

    render(){
        const {quest} = this.props;
        const {pages} = quest || {pages: []}


        const columns = [
            {
                field: "id",
                fieldHeader: "ID",
                flex: 1
            },
            {
                field: "type",
                fieldHeader: "Тип",
                flex: 1
            }
        ]

        return(

            <div className="quest-pages">
                {!Array.isArray(pages) && <P404 error="Wrong pages data" />}
                { Array.isArray(pages) && <AdminTable 
                    rows={pages}
                    columns={columns}
                    autoHeight
                />}

            </div>
        )
    }
}

export default QuestPages