import React from 'react'

/**
 * # Base class for a page block
 * It should have the **defaultConfig** static property
 * that has stated block's type and it's fields
 * The special field 'otherProps' gets spread into 
 * the result object when preparing default values 
 * via geDefaultValues function.
 * NOTE: I'm not sure why exactly we need the otherProps, 
 * but I'm adding them... just in case
 *
 */
class BaseBlock extends React.Component{

    static getDefaultValues = ( data = {} ) => {
        // fetch 'value' property from each key in 'fields' from config
        const values = Object.keys(this.defaultConfig.fields)
            .reduce( (res, key) => {

                // if key is 'otherProps' - spread its value into result
                return key === "otherProps" ? {
                    ...res, ...this.defaultConfig.fields[key].value
                } 
                // otherwise - add property to result
                :

                    {...res, 
                        [key]: this.defaultConfig.fields[key].value
                    }
                
            } 
            , {})

            // overwrite default values with passed data, if any
        return {...values, ...data};
    }

    static extend(descendant) {
        Object.assign(descendant, this)
    }
}

BaseBlock.defaultConfig = {
    type: "imageAuto",
    fields: {
        image: {
            type: "string",
            input_type: "string",
            value: ""
        },
        noImageText : {
            type: "string",
            input_type: "string",
            value: "No image"
        },
        otherProps : {
            type: "object",
            editComponent: "json",
            value: {}
        }
    }
}

export default BaseBlock

/**
 * Function to extract default values from page block config and parse them with block data, if any
 * @param {object} config defaultConfig object from given block
 * @param {object|undefined} data optional object with actual block data to overwrite the config values
 */
export const getDefaultValues = ( config, data = {} ) => {
    // fetch 'value' property from each key in 'fields' from config
    const values = Object.keys(config.fields)
        .reduce( (res, key) => {

            // if key is 'otherProps' - spread its value into result
            return key === "otherProps" ? {
                ...res, ...config.fields[key].value
            } 
            // otherwise - add property to result
            :

                {...res, 
                    [key]: config.fields[key].value
                }
            
        } 
        , {})

    // overwrite default values with passed data, if any
    return {...values, ...data};
}