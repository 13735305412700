import { Button, TextField, Typography } from '@material-ui/core'
import { Save } from '@material-ui/icons';
import React from 'react'
import baseClientService from '../../services/base-client-service';
import { equals } from '../../utils';
import BaseControl from './controls/base-control';
import { getControl } from './controls/control-map';
import { notify } from '../../redux/notificationActions';
// import defaultQuestData from '../../data/default-quest-data.json'

const fields = [
    {name:'id', label: "ID" },
    {name:'label', label: "Название"},
    {name:'cssclass', label: "CSS класс"},
    {name:'bg', label: "Цвет фона (hex)"},
    {name:'startPage', label: "id Начальной страницы"},
]

class QuestTemplateForm extends React.Component{
    state = {
        quest: {},
        original: {},
        template: null,
    }

    componentDidMount(){
        const {quest} = this.props;
        // store quest data and its copy as original, to compare later
        this.setState({ quest, original: baseClientService.Copy(quest) })
    }

    componentDidUpdate(prevProps){
        const {quest} = this.props
        if( !equals(quest, prevProps.quest) ){
            this.setState({ quest, original: baseClientService.Copy(quest) })
        }
    }


    onControlChange = ( id, value ) => {
        const {quest} = this.state;
        const {template} = this.props;
        const {values = {}} = template || {};
       
        console.debug("updating", id,  "to", value, "in quest:", quest)
        // if not present in quest nor in template - do not update
        if( !quest.hasOwnProperty(id) && !values.hasOwnProperty(id)){
            notify("Ошибка: в игре нет такого значения");
            return console.error(`update failed: quest and quest template do not have property [${id}]`)
        }

        
        this.setState({quest: {...quest, [id]: value }})
    }

    render(){
        const {quest, original} = this.state;
        const {template, onChange} = this.props;
        const {template_label, values} = template || {};

        // rough comparison
        // const hasChanges =  JSON.stringify(quest) !== JSON.stringify(original)
        const hasChanges =  !equals(quest, original)

        return(          

            <div className="quest-template-form flex column">
                <div className="template-header flex row align-items-center justify-content-space-between">
                    <span>{template_label}</span>
                    <div className="buttons">
                        <Button variant="contained" color="primary"
                        disabled={!hasChanges}
                        onClick={() => onChange( quest )}
                        >
                            <Save/> Save
                        </Button>
                    </div>
                </div> 
                {Object.keys(values).map( key => {
                    const {showInCreate, ...item} = values[key];
                    const Control = getControl(item.input_type)
                    // every control have to use onChange as following: onChange( itemId, itemValue )
                    return <Control key={`template-control-${key}`} {...item} id={key} onChange={this.onControlChange} value={quest[key]} />

                })}
            </div>
        )
    }
}

export default QuestTemplateForm