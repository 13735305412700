import { List, TextField, Typography } from '@material-ui/core'
import React from 'react'
import BaseControl from './base-control';
import { getControl } from './control-map';
import { notify } from '../../../redux/notificationActions';


class ObjectItems extends BaseControl{
   

    updateItem = ( fieldId, newFieldValue ) => {
        
        // id is of ObjectItems element, we need to use onChange on it's data (value)
        const {id, onChange, value = {}} = this.props; 

        console.debug( "updateItem props:", this.props);
        
        console.debug("updating object's", value, "property", fieldId, "to", newFieldValue )

        // update item by it's id within value
        value[fieldId] = newFieldValue

        if( !value.id ) value.id = id;

        // call onChange with ObjectItems elemen id and updated value
        console.debug("updating object", id, "to", value )
        onChange( id, value )
    }

    render(){
        console.debug("object items props", this.props)
        const {id, label, descr, id_field, items = [], value = {}, onChange } = this.props;

        return <div className="object-items">
            <Typography variant='h3'>{id} :: {label || "???"} </Typography>
            { descr && <Typography variant='body'>{descr}</Typography> }
            <List>
                {
                    items.map( 
                        item => {
                            const Control = getControl(item.input_type)
                            return <Control key={`object-item-${id}-${item.id}`} 
                            {...item} 
                            // we need to get value from value object by item's id
                            // e.g. value object is {id: "qr-task1", text: "Some text"}
                            // item id is "text"
                            // we need to get value["text"] or fallback to 'defaultValue' field from template                
                            value={value[item.id] || item.defaultValue } 
                            onChange={this.updateItem} 
                            notify={notify}
                            />
                        } 
                    )
                }
            </List>
        </div>
    }
}

BaseControl.extend(ObjectItems);

export default ObjectItems