import React from 'react'
import {CircularProgress, Tab, Tabs, TextField, Typography} from "@material-ui/core"
import defaultQuestData from "../../data/default-quest-data.json";
import QuestService from '../../services/quest-service';
import P404 from '../template/404';
import { NavLink } from 'react-router-dom';
import QuestDetails from './quest-details';
import QuestPages from './quest-pages';
import QuestTemplate from './quest-template';
import AutoBreadcrumbs from '../template/auto-breadcrumbs';
import { Home } from '@material-ui/icons';
import "./quest-editor.scss"

class QuestEditor extends React.Component{

    state = {
        quest: null,
        error: null
    }

    componentDidMount(){
        this.fetchQuestData();
    }

    async fetchQuestData(){
        const {id, tab, page} = this.props.match.params;
        if( ! id ){
            // new 
            return this.setState({quest: {...defaultQuestData}})
        } 

        const quest = await QuestService.get2(id);
        if( !quest ){
            return this.setState({error: "Quest not found!"})
        }

        this.setState({quest})
    }

    updateQuestData = async ( questData ) => {
        try{
            await QuestService.updateQuestData( questData )
            // reload
            this.fetchQuestData();
        }catch(e){
            console.error(e.message)
            console.log(e);
        }
    }

    render(){

        let {id, tab, page} = this.props.match.params;

        tab = tab || "details"

        const {error, quest } = this.state;

        if( error ) return <P404 error={error} />

        if( !quest) return <CircularProgress/>

        const baseLink = id === "new" ? `/quest/` : `/quest/edit/`;
        console.debug("tab:", tab)

        return( <div className={`page quest-editor grow-1 ${quest.cssclass || ""}`}>
            <AutoBreadcrumbs 
                items={[
                    {
                        to: "/",
                        icon: <Home/>
                    },
                    {
                        to: "/quest",
                        text: "Список квестов"
                    },
                    {
                        to: `${baseLink}${id}`,
                        text: id === "new" ? "Новый" : id
                    }
                ]}
            />
            <Tabs value={tab} key="quest-editor-tabs" >
                <Tab value={"details"} component={NavLink} to={`${baseLink}${id}`} label="Детали"></Tab>
                <Tab value={"template"} component={NavLink} to={`${baseLink}${id}/template`} label="Шаблон" ></Tab>
                <Tab value={"pages"} component={NavLink} to={`${baseLink}${id}/pages`} label="Страницы" ></Tab>
            </Tabs>
            {tab === "details" && <QuestDetails {...{quest}} />}
            {tab === "pages" && <QuestPages {...{quest}} /> }
            {tab === "template" && <QuestTemplate {...{quest}} onChange={this.updateQuestData}/> }
            
        </div> )
    }
}

export default QuestEditor