import React from 'react'
import './player.scss'
import {Button} from '@material-ui/core'
import {connect }from 'react-redux'
import A from '../../../../redux/actionTypes'

const MEDIA = process.env.REACT_APP_MEDIA
class SelectPlayer extends React.Component{


    setPlayer( player ){
        this.props.setplayer( player )
        const next = this.props.onNext || console.log

        next( this.props.next )
    }

    render(){
        
        const {players, bgimage, onNext = value => console.log("Next is:", value) } = this.props
        if( !Array.isArray(players)) return <div>Error...</div>
        
        return(
            <div className="player-select" style={ {backgroundImage: `url(${MEDIA}${bgimage})` }} >
                {players.map((p, ind) => {
                    return <Button 
                    key={ind} 
                    className="player-button" 
                    onClick={() => this.setPlayer(p)} >
                        <img src={`${MEDIA}${p.sprite}`} />
                        {p.name}
                        </Button>
                })}
            </div>
        )
    }
}

export default connect(
    state => ({ player: state.player}),
    dispatch => ({
        setplayer : player => dispatch( {type: A.SET_PLAYER, payload: player})
    }))(SelectPlayer)