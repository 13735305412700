import { TextField } from '@material-ui/core'
import React from 'react'
import defaultQuestData from '../../data/default-quest-data.json'

const fields = [
    {name:'id', label: "ID" },
    {name:'label', label: "Название"},
    {name:'cssclass', label: "CSS класс"},
    {name:'bg', label: "Цвет фона (hex)"},
    {name:'startPage', label: "id Начальной страницы"},
]

class QuestDetails extends React.Component{
    state = {
        quest: {...defaultQuestData}
    }

    componentDidMount(){
        const {quest} = this.props;
        this.setState({ quest: {...defaultQuestData, ...quest} })
    }

    render(){
        
        return(
            

            <div className="details flex column">
                {fields.map( f => <TextField key={`field-${f.name}`} {...f} value={this.state.quest[f.name] || "" } /> )}
                </div>
        )
    }
}

export default QuestDetails