import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import QuestService from '../../services/quest-service';
import P404 from '../template/404';
import QuestTemplateForm from './quest-template-form';


class QuestTemplate extends React.Component{
    state = {
        template_list: null,
        template: null,
        quest: null,
        error: null
    }

    async componentDidMount(){
        const {quest} = this.props;

        const template_list = await QuestService.getTemplateList();

        this.setState({quest, template_list}, () => {
            this.fetchTemplateData();
        })

    }

    async fetchTemplateData(){
        const {quest} = this.props;
        const {template_id } = quest || {}
        console.debug("Quest is:", quest);
        let error;

        if( !template_id ){
            return this.setState({template_id, template: null})
        }

        const template = await QuestService.getTemplate(template_id)

        if( !template ) error = QuestService.error || "Unknown quest service error";

        this.setState({template_id, template, error })
    }

    render(){
        
        const {onChange} = this.props;

        const {quest, template, error, template_list  } = this.state;

        const {template_id} = template || { template_id: ""}

        const columns = [
            {
                field: "id",
                fieldHeader: "ID",
                flex: 1
            },
            {
                field: "type",
                fieldHeader: "Тип",
                flex: 1
            }
        ]

        if( error) return <P404 error={error }/>
        if( !template_list || !quest ) return <CircularProgress />


        console.debug("template_id:", template_id)
        console.debug("template:", template)

        return(

            <div className="quest-template">
                {!template && 
                <FormControl>
                    <InputLabel htmlFor="select-template">Выбор шаблона</InputLabel>
                    <Select id="select-template" name="template" value={template_id}>
                        <MenuItem value="">Без шаблона</MenuItem>
                        {template_list.map( qt => <MenuItem value={qt.template_id}>{qt.template_label || "???"}</MenuItem> )}
                    </Select> 
                </FormControl>
                }
                { 
                // as quest we pass questData which is the normalized quest data, without template mixed in
                template && <QuestTemplateForm {...{quest: quest.questData, template, onChange}} />}


            </div>
        )
    }
}

export default QuestTemplate