import { Button, CircularProgress, LinearProgress } from '@material-ui/core'
import React from 'react'
import './video.scss'
import {imgSrc} from  '../../../../utils'
const MEDIA = process.env.REACT_APP_MEDIA

class VideoOnBackground extends React.Component{

    componentDidMount(){
        // const {id} = this.props
        // document.getElementById(`video-${id}`).addEventListener( "onclick", ev => ev.currentTarget.play() )
    }

    blockClick = () => {
        
        const vid = document.getElementById(`video-${this.props.id}`)
        if( vid.paused )
            vid.play()
        else
            vid.pause()
    }

    render(){

        let {
            id,
            video, 
            bgimage, 
            description,
            videoProps = {
                "autoPlay": true
            },
            start_animation = "animate__zoomIn", // animation to play when video box is shown
            onNext = value => console.log("Next is:", value) ,
            next
    } = this.props
        // if( !Array.isArray(buttons)) return <div>Error...</div>

        videoProps = {
            playsInline: true
            ,
            ...videoProps
        }
        
        return(
            <div className="video-block" key={id} onClick={this.blockClick  } >
            <div className={`background`} style={ {backgroundImage: `url(${imgSrc(bgimage)})` }}> 
                <div className="video-loading-progress">
                    <CircularProgress />
                </div> 
                <video id={`video-${id}`} src={imgSrc(video) } onEnded={() => onNext(next )}  {...videoProps} 
                className={videoProps.className ? `${videoProps.className} animate__animated ${start_animation}` : `animate__animated ${start_animation}`} />
            </div>
            {description && <div className="description">{description}</div> }
            </div>
        )
    }
}

export default VideoOnBackground