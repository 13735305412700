import { Button } from '@material-ui/core'
import React from 'react'
import './iwb.scss'
import { updateGameState } from '../../../../redux/questActions'
import { imgSrc } from '../../../../utils'

const MEDIA = process.env.REACT_APP_MEDIA

class ImageWithButtons extends React.Component{

    onButtonClick = (button, onNext) => {
        const { next } = this.props;

        // if changeState object is present
        if( button.changeState && typeof button.changeState === "object" ){
            updateGameState(button.changeState);
        }

        onNext(button.next || next);
    }

    render(){

        const {buttons, image, description, prompt, onNext = value => console.log("Next is:", value) } = this.props
        // if( !Array.isArray(buttons)) return <div>Error...</div>
        
        return(
            <>
            <div className="background" style={ {backgroundImage: `url( ${imgSrc(image)}` }} >
                {prompt && <div className="prompt">{prompt}</div> }
                {buttons && buttons.map((b, ind) => {
                    return <Button key={ind} {...b.props} onClick={() => this.onButtonClick(b, onNext)}>{b.text}</Button>
                })}
                {description && <div className="description">{description}</div> }
            </div>
            </>
        )
    }
}

export default ImageWithButtons