import './brawls.scss'

const default_props = {
  video: {
    bg: 'black',
    color: 'white',
    bgimage: 'brawls/brawls-bg.jpg',
    videoProps: { autoPlay: true },
    inputProps: {
      type: 'text',
    },
  },
  qrScan: {
    bgimage: 'brawls/brawls-bg.jpg',
    promptProps: { className: 'prompt brawlized' },
    buttonText: 'הכנס את הקוד',
    buttonProps: { className: 'brawl-button' },
  },

  vault2: {
    bgimage: 'brawls/brawls-bg.jpg',
    textFieldProps: {
      className: 'vault-input centered',
    },
    buttonProps: { className: 'brawl-button' },
    promptProps: { className: 'prompt brawlized' },
    next: 'shelly-success',
    intro_text: 'הכנס את הקוד',
    success_text: 'מצוין!',
    skipSuccessImage: true,
  },

  touchImage: {
    image: 'brawls/brawls-bg.jpg',
    messageProps: { className: ' prompt brawlized' },
  },
}
class BrawlsTemplateHe {
  data = {
    id: null,
    bg: '#0249bf',
    startPage: 'start',
  }

  constructor(config = {}) {
    this.data = { ...this.data, ...config }

    // check if pages is an array
    if (!Array.isArray(this.data.pages)) {
      throw new Error('BrawlsTemplateHe: data.pages is in wrong format')
    }

    // parse pages and add overlay images if needed
    this.data.pages = this.data.pages.map((p) => {
      p.code = p.code
        ? Array.isArray(p.code)
          ? p.code
          : String(p.code).split('|')
        : p.code

      if (p.category) {
        p.before = {
          type: 'overlayImage',
          image: `brawls/categories/${p.category}-he.png`,
          // style: {
          // width: "290px",
          // bottom: "40px",
          // right: "80px"
          // },
        }
      }
      // add image folder to video path
      if (p.video) p.video = `${config.image_folder}/${p.video}`

      const defaultProps = default_props[p.type] || {}
      console.debug('default props for', p.type, defaultProps)
      return { ...defaultProps, ...p }
    })
  }
}

export default BrawlsTemplateHe
