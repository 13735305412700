import React from 'react'
import {connect} from 'react-redux'
import A from  '../../../../redux/actionTypes'
import {imgSrc} from '../../../../utils'
import './random-dead.scss'

class RandomDead extends React.Component{

    state = {
        killed: null,
        roomsPassed: []
    }

    componentDidMount(){
        let {roomsPassed} = this.state
        let {player, players, killPlayer, id} = this.props
        
        players = [...players] //copy it

        // Only if this room hasn't been played before
        if( !roomsPassed.includes(id) ){
            const ind = players.findIndex( p => p.id === player.id )
            players.splice(ind, 1) // remove self
    
            // choose random player
            const rand = Math.floor(Math.random() * players.length);
            const killed = players[rand]
            killPlayer( killed  )

            roomsPassed = [...roomsPassed, id ]
            this.setState ({killed, roomsPassed })
        }

    }

    render(){
        const {killed} = this.state
        const {onNext = console.log, next} = this.props
        return(
            <div className="body-found" onClick={() => onNext(next)} style={{backgroundImage: `url(${imgSrc("dead-body-reported.jpg")})`}}>
                {killed && <img src={imgSrc(killed.sprite)} className="body"/>}                
            </div>
        )
    }
}

export default connect(
    state => ({
        player: state.player,
        players: state.players
    }),
    dispatch => ({
        killPlayer: payload => dispatch({type: A.KILL_PLAYER, payload })
    })
)(RandomDead)