import { TextField } from '@material-ui/core'
import React from 'react'

class BaseControl extends React.Component{

    constructor(props){
        super(props)
    }

    /**
     * Pass extended class to the function to copy all properties and methods
     * @param {*} descendant 
     */
    static extend(descendant) {
        Object.assign(descendant, this)
    }

    render(){
        console.debug("control props id:", this.props)
        const {id, type, value = "", onChange, ...other} = this.props;
        return <TextField fullWidth value={value }  {...other } onChange={({currentTarget}) => onChange( id, currentTarget.value )} />
    }
}

export default BaseControl