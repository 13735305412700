import { Box, Button, CircularProgress, List, ListItem, Typography } from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import ActionTypes from '../../redux/actionTypes';
import QuestService from '../../services/quest-service';
import P404 from '../template/404';
import AdminTable from '../template/admin-table';




class QuestList extends React.Component{

    state = {
        list: null,
        error: null
    }

    componentDidMount(){
        this.fetchList();
    }

    async fetchList(){
        try{

            const list = await QuestService.getAll();
            this.setState({list});
        }catch(e){
            console.debug(e);
            this.setState({error: e.message})
        }
    }

    render(){
        const {list, error} = this.state;
        const {page, quest, action_dispatch} = this.props;

        
        if( error ) return <P404 error={error} header="Error" />
        if( !Array.isArray(list)) return <CircularProgress />

        const resetQuest = () => action_dispatch({type: ActionTypes.RESET_GAME});

        const questIsRunning = Boolean(quest);

        const columns = [
            {
                field: "id",
                headerName: "ID",
                flex: 1
            },
            {
                field: "label",
                flex: 1,
                headerName: "Название",
                renderCell: (params) => questIsRunning ? params.value : <Link to={`/quest/${params.row.id}`}>{params.value}</Link> ,
            },
            {
                field: "actions",
                headerName: "",
                renderCell: (params) => <Link to={`/quest/edit/${params.row.id}`}><Edit/></Link>
            }
            // { field: "course_title", headerName: "Title", flex: 2 },
            // { field: "type_title", headerName: "Type", flex: 2 },
        ]

        return(<Box className='page' padding={1} flexGrow={1} >
            <Typography vriant="h1">Квесты</Typography>
            {quest && <Box bgcolor="info.main" padding={1}>
                <div>Запущен квест:</div> 
                <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                    <Button component={Link} variant="contained" to={`/quest/${quest.id}`}>{quest.label}</Button>
                    <Button variant="contained" color="secondary" onClick={resetQuest} ><Close/> Сбросить</Button>
                </Box>
                </Box>}
            <AdminTable 
                rows={list}
                columns={columns}
                autoHeight
            />
          
            
        </Box>)
    }
}

export default connect( 
    state => ({ 
        pages_done: state.quest.pages_done, 
        page: state.quest.page, 
        quest: state.quest.quest 
    }),
    dispatch => ({
        action_dispatch: a => dispatch(a)
    })
)(  QuestList) 

