import React from 'react'
import {BrowserRouter as Router } from 'react-router-dom'
// import Footer from  '../template/footer'
// import Navbar from '../template/navbar'
import Routes from '../routes'
import routes from '../../data/routes'
import Notifications from "../template/notifications";
import { connect } from 'react-redux';
import { clearAllMessages } from '../template/notifications/notificationActions';
import { deleteMessage } from '../../redux/notificationActions';


class Main extends React.Component{



    render(){
        const {messages, onCloseAll, onCloseMessage } = this.props;
        return (
            <Router>
                {/* <Navbar/>           */}
                <div className="main">
                    <Routes routes={routes} className="main"/>
                </div>
                {/* <ContactForm /> */}
                {/* <ReduxModal/> */}
                {/* <Toast /> */}
                {/* <Footer/>   */}
                <Notifications {...{messages, onCloseAll, onCloseMessage}} />
            </Router>
        )
    }
}


const stateToProps = (state)  => ({messages: state?.notifications?.messages })
const dispatchToProps = (dispatch) => ({
    onCloseAll: () => clearAllMessages()(dispatch),
    onCloseMessage:  (payload) => deleteMessage( payload )(dispatch)
})
export default connect( stateToProps, dispatchToProps )( Main)