import React from 'react'
import {imgSrc} from '../../../../utils'
import "./touch-image.scss"

const Image = props => {

    const {
        image, 
        className, 
        onNext, 
        next,
        messageProps = {}, 
        ...other } = props

    const message = props.message || props.text

    return image ? 
        <div 
        className="touch-image-wrapper" 
        style={{backgroundImage: `url(${imgSrc(props.image)})`}}
        onClick={()=> onNext(next)}  
        {...other}
        >
        {message ? <div className="prompt" {...messageProps} >{message}</div> : "" }
        </div>
    

    : "No image"

}

export default Image