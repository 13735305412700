import { TextField } from '@material-ui/core'
import React from 'react'
import BaseControl from './base-control';

class Text extends BaseControl{
   

    render(){
        const {id, type, onChange, value = "",  ...other} = this.props;
        return <TextField multiline fullWidth minRows={3} maxRows={6} value={value} {...other } 
        onChange={({target})=>onChange(id, target.value)} />
    }
}

BaseControl.extend(Text)

export default Text