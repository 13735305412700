// import quests from '../data/quests'
import BrawlsTemplateHe from '../components/quest-templates/brawls-template-he'
import BrawlsTemplateRu from '../components/quest-templates/brawls-template-ru'

// import quests from '../data/quests-db.json'
import questData from '../data/quest-data.json'
import questTemplates from '../data/quest-templates.json'
import baseClientService from './base-client-service'

/**
 * Quest templates classes from /components/quest-templates folder
 * Add new templates here.
 *
 * The template purpose is to load quest-specific css and optionally parse pages data
 * (it is needed sometimes to include some specific items in pages based on their type and template config)
 */
const templates = {
  'brawls-template-ru': BrawlsTemplateRu,
  'brawls-template-he': BrawlsTemplateHe,
}

class QuestService extends baseClientService {
  // static async getAll() {
  //   const cacheIndex = 'get-all'
  //   return this.getCachedOr(cacheIndex, () => {
  //     this.cache(cacheIndex, quests)
  //     return this.getCached(cacheIndex)
  //   })
  // }

  static async get(quest_id) {
    const all = await this.getAll()

    const questData = all.find((q) => q.id === quest_id)

    if (!questData) {
      throw new Error('Quest not found')
    }

    console.debug('quest data:', questData)

    if (!templates[questData['template']])
      throw new Error('template not found in quest config')

    // create instance of template with qest data
    const quest = new templates[questData['template']](questData)

    // return parsed data
    return { ...quest.data }
  }

  /**
   * Uses the advanced templating system from quest-templates.json + quest-data.json
   */
  static async getAll(forceFetch = false) {
    const cacheIndex = 'all-quests-2'

    if( forceFetch ){
      // clear cache to force-fetch the data
      this.clearCache(cacheIndex);
    }

    return this.getCachedOr(cacheIndex, async () => {
      let allGames = [...questData]

      let i = 0
      while (i < allGames.length) {
        const questItem = allGames[i]
        // parse each quest with its template
        allGames[i] = await this.parseQuestData(questItem)
        console.debug(allGames[i])
        i++
      }

      this.cache(cacheIndex, allGames)
      return this.getCached(cacheIndex)
    })
  }

  /**
   * Returns raw quest data without applying templates
   */
  static async getQuestData( forceFetch = false ){
    const index = "quest-data";

    if( forceFetch ){
      // clear cache to force-fetch the data
      this.clearCache(index);
    }

    return this.getCachedOr(index, () => {
      // TODO: fetch from server
      this.cache(index, questData);
      return this.getCached(index);
    })
  }

  static async get2(quest_id) {
    const all = await this.getAll()
    return all.find((q) => q.id === quest_id)
  }

  /**
   * Parses quest data using its template id
   * @param {*} data
   */
  static async parseQuestData(data) {
    let { template, pages: pagesData, ...other } = data
    if (!template) throw new Error('Template not set')
    let templateData = questTemplates.find((t) => t.template_id === template)
    if (!templateData) throw new Error('Template data not found')

    // create object copy
    templateData = this.Copy(templateData)

    // mix in the rest of quest value into template
    console.debug(
      'mixing in quest data into template data:',
      Object.keys(other),
    )
    templateData = { ...templateData, ...other }

    let { pages } = templateData

    // cycle through pages data and overwrite template values with them
    let i = 0
    while (i < pagesData.length) {
      const page = pagesData[i]
      const pageIndex = pages.findIndex((p) => p.id === page.id)
      if ( pageIndex>=0 ) console.debug('page found', page.id, pageIndex)
      else console.debug('!!! page not found', page.id)
      // overwrite template page with page data
      pages[pageIndex] = { ...pages[pageIndex], ...page }
      i++
    }

    // parse every page for additional values
    console.debug('parsing pages')
    i = 0
    while (i < pages.length) {
      pages[i] = await this.parsePage(pages[i], templateData)
      i++
    }

    // replace pages with parsed array
    console.debug(
      'replacing pages with parsed pages and adding the original data',
    )
    templateData = { ...templateData, pages, questData: { ...data } }

    console.debug('returning parsed template:', templateData)
    return templateData
  }

  static async parsePage(page, template) {
    // console.debug('parsing page', page, 'with', template)
    // prepend video with media (image) folder path
    if (page.video) {
      page.video = Boolean(template.image_folder)
        ? // if template has image folder defined - prepend video with it, otherwise use template id
          `${template.image_folder}/${page.video}`
        : `${template.id}/${page.video}`
    }
    return page
  }

  /**
   * Updates single quest data (normalized) within questData block (TODO update on server)
   * @param {*} newData
   */
  static async updateQuestData(newData) {

    const currentQuestData = await this.getQuestData();

    console.debug(
      'Updating quest',
      newData,
      'id is',
      newData.id,
      '\ncurrent quest data is:',
      currentQuestData,
    )

    // find index in array
    const index = currentQuestData.findIndex((q) => q.id === newData.id)
    if (index < 0) throw new Error('Quest not found : id=', newData.id)

    // update
    // TODO: update on server
    currentQuestData[index] = this.Copy(newData)

    // clear cache
    // this.clearCache('get-all')
    this.clearCache('all-quests-2')
    return true
  }

  static async updateTemplate(newData) {
    console.debug(
      'Updating template',
      newData,
      'id is',
      newData.id,
      '\ntemplates data is:',
      questData,
    )

    // find index in array
    const index = questData.findIndex((q) => q.id === newData.id)
    if (index < 0) throw new Error('Quest not found : id=', newData.id)

    // update
    questData[index] = this.Copy(newData)

    // clear cache
    // this.clearCache('get-all')
    this.clearCache('all-quests-2')
    return true
  }

  static async getTemplateList() {
    // TODO: replace with server call
    return questTemplates.map((qt) => {
      const { template_id, template_label, language } = qt
      return { template_id, template_label, language }
    })
  }

  static async getAllTemplates(){
    const index = "all-templates";
    return this.getCachedOr(index, () => {
      // TODO: replace with server call
      this.cache(index, questTemplates);
      return this.getCached(index);
    });
  }

  static async getTemplate(template_id) {
    if (!template_id) return this.Error('No template id passed')

    const templates= await this.getAllTemplates();
    // TODO: replace with server call
    return templates.find((qt) => qt.template_id === template_id)
  }
}

baseClientService.extend(QuestService)

export default QuestService
